<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
          <div class="image">
            <img src="img/android-chrome-512x512.png" alt="" />
            <div class="main" data-img-url="img/android-chrome-512x512.png"></div>
          </div>
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name"><span class="coloring">DRAIN</span></h3>
            <h3 class="job">
              <AnimationText />
            </h3>
          </div>
          <div class="text">
            <p>
              DRAIN is an experiment in DeFi on the Stellar DEX. Assets will soon be chosen and information will be output on the home page. <br> There are no social media groups and there is No solicitation to buy DRAIN.
            </p>
          </div>
          <div class="edrea_tm_button" id="aboutPopup">
            <a href="https://stellar.expert/explorer/public/asset/DRAIN-GD3G7RPMMGPS7Y3FBEMRZQCDHHQRLIW67ZB4T57AW5SZDVJHVUD7PLUG-2" target="_blank" rel="noopener noreferrer">DRAIN Asset Link</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}
</style>
